import Loader from "../../assets/icons/logoHalf.png";

const RootLoading = () => {
  return (
    <div className="flex justify-center items-center min-h-screen ">
      <div className="rounded-full bg-gray-300 w-36 h-36 animate-ping duration-200  flex items-center justify-center">
        <img src={Loader} alt="" className="w-32 md:w-36  p-3 " />
      </div>
    </div>
  );
};

export default RootLoading;
