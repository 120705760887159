import { useNavigate } from "react-router-dom";
import Not from "../../../assets/images/404.png";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-3 min-h-screen items-center justify-center px-4">
      <img src={Not} alt="" />
      <div className="items-center font-medium text-lg">
        Sorry, but this page doesn't exist
      </div>
      <div className="text-center font-medium capitalize cursor-pointer">
        Maybe you would like to go back to our{" "}
        <span className="text-primary" onClick={() => navigate("/")}>
          homepage
        </span>{" "}
        or{" "}
        <span
          className="text-primary cursor-pointer"
          onClick={() => navigate("/contact")}
        >
          contact us ?
        </span>
      </div>
    </div>
  );
};

export default NotFound;
