import { lazy } from "react";
import Redirect from "../Redirect";
import NotFound from "../../components/ui/page/NotFound";

// const ControlPanel = lazy(() => import("../../layout"));

const ControlPanel = lazy(() => import("../../layout/index"));
const Home = lazy(() => import("../../pages/Home"));
const Contact = lazy(() => import("../../pages/Contact"));
const Privacy = lazy(() => import("../../pages/Privacy"));
const Terms = lazy(() => import("../../pages/Terms"));
const Faq = lazy(() => import("../../pages/Faq"));
const VirtualCard = lazy(() => import("../../pages/VirtualCard"));
const VirtualWallet = lazy(() => import("../../pages/VirtualWallet"));
const MoneyTransfer = lazy(() => import("../../pages/MoneyTransfer"));
const Business = lazy(() => import("../../pages/Business"));
const About = lazy(() => import("../../pages/About"));
const Signup = lazy(() => import("../../pages/Signup"));
const Login = lazy(() => import("../../pages/Login"));
const Kyc = lazy(() => import("../../pages/Kyc"));

const pagesRoute = [
  {
    path: "/",
    component: Home,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/contact",
    component: Contact,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/virtual-card",
    component: VirtualCard,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/virtual-wallet",
    component: VirtualWallet,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/money-transfer",
    component: MoneyTransfer,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/business",
    component: Business,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/about",
    component: About,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  {
    path: "/privacy",
    component: Privacy,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/terms",
    component: Terms,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/faq",
    component: Faq,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/login/*",
    component: Login,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/signup/*",
    component: Signup,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/kyc/*",
    component: Kyc,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },

  // {
  //   path: "/*",
  //   component: ControlPanel,
  //   meta: {
  //     redirectUrl: "/login",
  //     protectedRoute: true,
  //     role: "admin",
  //   },
  // },

  {
    path: "/*",
    component: NotFound,
    meta: {
      redirectUrl: "/login",
      protectedRoute: true,
      role: "admin",
    },
  },

  {
    path: "*",
    component: Redirect,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
];

export default pagesRoute;
