import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "../components/utils/ScrollToTop";
import Redirect from "./Redirect";
import pagesRoute from "./routes/pages";
import RootLoading from "../components/RootLoading/RootLoading";

const Router = () => {
  const loggedIn = true;
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Toaster />
      <Suspense fallback={<RootLoading />}>
        <Routes>
          {pagesRoute.map((route) => {
            // if (
            //   route.meta.protectedRoute &&
            //   route.meta.role === "admin" &&
            //   loggedIn
            // ) {
            //   return (
            //     <Route
            //       key={route.path}
            //       path={route.path}
            //       element={<route.component />}
            //     >
            //       {controlPanel.map((cRoute) => (
            //         <Route
            //           key={cRoute.path}
            //           path={cRoute.path}
            //           element={
            //             <Suspense>
            //               <cRoute.component />
            //             </Suspense>
            //           }
            //         />
            //       ))}
            //     </Route>
            //   );
            // }
            if (route.meta.protectedRoute && !loggedIn) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Redirect redirectUrl={route.meta.redirectUrl} />}
                />
              );
            }

            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <route.component redirectUrl={route.meta.redirectUrl} />
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
